import React from "react";
import "./Newsletter.scss";

import { Link } from "react-router-dom";

import December from "../../assets/newsletters/MPHCA December e-Newsletter.pdf";
import November from "../../assets/newsletters/MPHCA November e-Newsletter.pdf";

function NewsLetter() {
  return (
    <div className="newsletter">
      <a href={November}>
        <button className="newsletter__button">November Newsletter</button>
      </a>
      <a href={December}>
        <button className="newsletter__button">December Newsletter</button>
      </a>

      <Link to="/anti-racism">
        <button className="newsletter__button">Anti Racism Charter</button>
      </Link>
    </div>
  );
}

export default NewsLetter;
